<template>
  <DkLogin ref="dkLogin" :serviceUrl="serviceUrl" @onSubmit="login">
    <template #logo>
      <span class="logo-heli">
        <img src="@/assets/images/logo_helinox_login.png" alt="" class="img_logo" />
      </span>
      <span class="logo-intranet">{{ $t("message.intranet") }}</span>
    </template>
    <template #footer>
      <div class="doc-footer" role="contentinfo">
        <h2 class="screen_out">서비스 이용정보</h2>
        <div class="info_corp">
          <small class="info_copy">
            © 2022, <a href="#none" target="_blank" class="link_txt">Helinox.</a> All Rights
            Reserved.
          </small>
        </div>
      </div>
    </template>
  </DkLogin>
</template>

<script>
import DkLogin from "@/components/login/DkLogin.vue";
import SvgIcon from "@/components/common/SvgIcon.vue";
import { LogoIntranet } from "@/assets/svg";

export default {
  name: "Login",
  components: {
    DkLogin,
    SvgIcon,
    LogoIntranet,
  },
  data() {
    return {
      // serviceUrl 각 사이트별로 전달
      serviceUrl: "",
      radioValue1: "a",
      radioValue2: "b",
      radioValue3: "c",
      select: "",
    };
  },
  created() {
    this.setServiceUrl();
  },
  methods: {
    login() {
      this.$refs.dkLogin.submit();
    },
    setServiceUrl() {
      const vueUrl = window.location.origin;
      const apiUrl = process.env.VUE_APP_API_URL;
      const rl = `${vueUrl}/login&rl=${apiUrl}/api/login/auth&vueUrl=${vueUrl}`;
      this.serviceUrl = `${apiUrl}/api/login/cas?loginUrl=${encodeURIComponent(rl)}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.logo {
  &-sm,
  &-intranet {
    display: inline-block;
    vertical-align: middle;
  }
  &-sm {
    --svg-width: 136px;
    --svg-fill: currentColor;
  }
  &-intranet {
    margin-left: 9px;
  }
}
</style>

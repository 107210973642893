<template>
  <div class="item_inp" :class="{ focus_inp: isFocus }">
    <input
      ref="input"
      v-model="input"
      :type="type"
      class="inp_comm"
      v-bind="$attrs"
      @input="updateInput"
      @blur="blur"
      @focus="focus"
    />
  </div>
</template>

<script>
export default {
  name: "DkLoginInput",
  props: {
    value: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
  },
  data() {
    return {
      isFocus: false,
    };
  },
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
  created() {
    if (this.saveId) {
      this.inputText = this.saveId;
    }
  },
  methods: {
    updateInput(e) {
      this.checkInput(e.data);
      this.$emit("input", this.input);
    },
    checkInput(word) {
      const check = /[^A-Za-z0-9!@#$%^&*()`₩~_+\-=[\]{};':"\\|,.<>/?]/gi; // 영문, 숫자, 특문 외 다른 문자가 있으면 true

      if (check.test(word)) {
        console.log();
        // 한글 입력
        this.input = this.input.replace(check, "");
        this.$emit("isError", true);
      } else {
        // 영문 대 소문자, 숫자, 특수문자 입력
        this.$emit("isError", false);
      }
    },
    focus() {
      this.isFocus = true;
    },
    blur() {
      this.isFocus = false;
      this.$emit("isError", false);
    },
  },
};
</script>

<template>
  <div class="container-doc container-login">
    <div class="doc-main" role="main">
      <div class="main-content">
        <div id="mainContent" class="content-article">
          <div class="wrap_login">
            <div class="inner_login">
              <h1 class="tit_login">
                <slot name="logo"></slot>
              </h1>
              <h2 class="screen_out">로그인</h2>
              <form ref="form" :action="casLoginObj.url" @submit="onSubmit">
                <fieldset>
                  <legend class="screen_out">로그인 정보 입력폼</legend>
                  <div class="box_login">
                    <DkLoginInput
                      :class="{ error_inp: isIdError }"
                      name="username"
                      placeholder="ID"
                      title="아이디 입력"
                      :value.sync="username"
                      @isError="checkUsername"
                    />
                    <DkLoginInput
                      :class="{ error_inp: isPwError }"
                      type="password"
                      name="password"
                      placeholder="Password"
                      title="비밀번호 입력"
                      :value.sync="password"
                      @keyup.enter.prevent.native="onSubmit"
                      @isError="checkPassword"
                    />
                  </div>
                  <p v-if="errorMsg.length > 0" class="txt_error">
                    {{ errorMsg[0] }}
                  </p>
                  <div class="login_chk">
                    <div class="check_g">
                      <input id="idsave" v-model="isSaveId" type="checkbox" class="inp_chk" />
                      <span class="ico_chk"></span>
                      <label for="idsave" class="lab_chk">
                        {{ $t("message.id_save") }}
                      </label>
                    </div>
                    <a :href="computedInitPassword" target="_blank" class="btn_reset">
                      {{ $t("message.password_reset") }}
                    </a>
                  </div>
                  <button type="button" class="btn_login" @click.prevent="onSubmit">
                    {{ $t("message.btn_login") }}
                  </button>
                  <slot name="otherMode"></slot>
                  <div class="select-box select-language">
                    <opt-comm v-model="lang" :options="languageList" @on-change="changeLang" />
                  </div>
                  <p class="info_caution">
                    {{ $t("message.forget_password") }}
                    <a :href="computedInitPassword" target="_blank" class="link_txt">
                      {{ $t("message.password_reset") }}
                    </a>
                    {{ $t("message.text_try") }}
                  </p>
                  <input v-model="serviceUrl" type="hidden" name="service" />
                  <input type="hidden" name="login-form-type" value="pwd" />
                  <input type="hidden" name="auto" value="true" />
                  <input type="hidden" name="_eventId" value="submit" />
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <slot name="footer"></slot>

    <slot name="etc"></slot>
    <Toast />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { EventBus } from "@/plugins/eventBus";
import Toast from "@/components/common/Toast.vue";
import { Checkbox } from "@/components/common/checkbox";
import DkLoginInput from "./DkLoginInput";
import OptComm from "@/components/common/select/OptComm";
import { Trans } from "@/translations";

export default {
  name: "DkLogin",
  components: {
    DkLoginInput,
    Checkbox,
    OptComm,
    Toast,
  },
  props: {
    type: {
      type: String,
      default: "user",
    },
    serviceUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      username: "",
      password: "",
      isSaveId: false,
      isIdError: false,
      isPwError: false,
      errorMsg: [],
      casLoginObj: {
        url: process.env.VUE_APP_PROXY_CAS_API,
        execution: process.env.VUE_APP_CAS_EXECUTION,
      },
      lang: "",
    };
  },
  computed: {
    ...mapState({
      languageList: (state) => {
        return state.languageList.map((item) => {
          return {
            value: item.code,
            name: item.name,
          };
        });
      },
    }),
    computedInitPassword() {
      return process.env.VUE_APP_IAM_ACCOUNT_URL + "/api/accounts/initpassword";
    },
  },
  watch: {
    lang: {
      immediate: true,
      handler(val) {
        document.title = this.$t("message.intranet", val);
      },
    },
  },
  created() {
    this.loginFail();
    this.loadUsername();
    this.getLanguageList();
    this.getLanguageCode();
  },
  methods: {
    ...mapActions(["getLanguageList"]),
    getLanguageCode() {
      const language = localStorage.getItem("locale");
      if (language) {
        this.$store.commit("CHANGE_LANGUAGE", language);
        Trans.changeLanguage(language);
      }
      this.lang = language || "ko";
    },
    changeLang() {
      this.$store.commit("CHANGE_LANGUAGE", this.lang);
      localStorage.setItem("locale", this.lang);
      Trans.changeLanguage(this.lang);
    },
    checkUsername(isInputError) {
      this.isIdError = isInputError;
      if (isInputError) {
        this.errorMsg = [];
        this.errorMsg.push(this.$t("message.pw_validation"));
      } else {
        if (!this.isPwError) this.errorMsg = [];
      }
    },
    checkPassword(isInputError) {
      this.isPwError = isInputError;
      if (isInputError) {
        this.errorMsg = [];
        this.errorMsg.push(this.$t("message.pw_validation"));
      } else {
        if (!this.isIdError) this.errorMsg = [];
      }
    },
    validation() {
      this.errorMsg = [];

      if (this.username === "" && this.password === "") {
        this.errorMsg.push(this.$t("message.login_validation"));
        this.isIdError = true;
        this.isPwError = true;
      }

      if (this.username === "") {
        this.errorMsg.push(this.$t("message.login_id_validation"));
        this.isIdError = true;
      }

      if (this.password === "") {
        this.errorMsg.push(this.$t("message.login_pw_validation"));
        this.isPwError = true;
      }

      return this.errorMsg.length === 0;
    },
    loadUsername() {
      if (localStorage.getItem("username")) {
        this.isSaveId = true;
        this.username = localStorage.getItem("username");
      }
    },
    saveUsername() {
      if (this.isSaveId) {
        localStorage.setItem("username", this.username);
      } else {
        localStorage.removeItem("username");
      }
    },
    onSubmit() {
      this.$emit("onSubmit", this.username);
    },
    submit() {
      if (this.validation()) {
        this.saveUsername();
        this.$refs.form.submit();
      }
    },
    loginFail() {
      const { retmsg, authority } = this.$route.query;
      if (authority === "false") {
        this.errorMsg.push(this.$t("message.admin_validation"));
        this.isIdError = true;
        this.isPwError = true;
      }
      if (retmsg) {
        const msg = decodeURIComponent(retmsg).replace(/\+/g, " ").replace("\\n", " ");
        this.errorMsg.push(msg);
        this.isIdError = true;
        this.isPwError = true;
      }
    },

    localLogin(e) {
      const id = e.target.innerText;
      this.username = id;
      this.password = "demo2021!@";
    },
  },
};
</script>
